//Add strings
gdprCookieNoticeLocales.pl = {
  description: 'Niniejszy serwis stosuje pliki cookies w celu zapewnienia Ci maksymalnego komfortu podczas przeglądania serwisu i korzystania z usług. W każdej chwili możesz zmienić ustawienia przeglądarki decydujące o ich użyciu.',
  settings: 'Ustawienia cookies',
  accept: 'Akceptuję cookies',
  statement: 'Nasza polityka cookies',
  save: 'Zapisz zmiany',
  always_on: 'Zawsze włączone',
  cookie_essential_title: 'Podstawowe cookies strony',
  cookie_essential_desc: 'Niezbędne pliki cookie pomagają w korzystaniu ze strony internetowej poprzez umożliwienie podstawowych funkcji, takich jak nawigacja po stronie i dostęp do bezpiecznych obszarów witryny. Strona internetowa nie może działać poprawnie bez tych plików cookie.',
  cookie_performance_title: 'Cookies dot. wydajności',
  cookie_performance_desc: 'Te pliki cookie są używane w celu zwiększenia wydajności i funkcjonalności naszych stron internetowych, ale nie są niezbędne do ich użycia. Na przykład przechowuje preferowany język lub region, w którym się znajdujesz.',
  cookie_analytics_title: 'Cookies dot. analityki',
  cookie_analytics_desc: 'Używamy analitycznych plików cookie w celu pomiaru interakcji użytkowników z treściami witryny, co pomaga nam dostosowywać nasze witryny i aplikacje w celu zwiększenia wygody użytkowników.',
  cookie_marketing_title: 'Cookies dot. marketingu',
  cookie_marketing_desc: 'Te pliki cookie służą do tego, aby wiadomości reklamowe były bardziej trafne dla Ciebie i Twoich zainteresowań. Chodzi o to, aby wyświetlać reklamy, które są trafne i przyciągają uwagę poszczególnych użytkowników, a tym samym są bardziej wartościowe dla wydawców i reklamodawców zewnętrznych.'
}
